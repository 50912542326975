<div class="self-assignable-workflow-link-preview-dialog">
  <div class="self-assignable-workflow-link-preview-dialog__header">
    <div class="self-assignable-workflow-link-preview-dialog__header-title">Self-assignment link created</div>

    <div class="self-assignable-workflow-link-preview-dialog__header-description">
      <div class="self-assignable-workflow-link-preview-dialog__header-description-text">
        Share this link with anyone who should be able to assign this workflow to themselves.
      </div>

      <cc-self-assignable-workflow-link-copy-box [linkId]="dialogData().linkId" />
    </div>
  </div>

  <div class="self-assignable-workflow-link-preview-dialog__actions">
    <app-button
      (click)="dialogRef.close()"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Secondary"
      class="self-assignable-workflow-link-preview-dialog__actions-secondary-action"
    >
      {{ 'common.buttons.ok' | translate }}
    </app-button>
  </div>
</div>
