import { Component, Input, type SimpleChanges, ViewEncapsulation, output, OnChanges, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';

import { DatePickerComponent } from '@core/components/date-picker/date-picker.component';
import { SelectComponent, type SelectGroup, type SelectOption } from '@core/components/select/select.component';

import { FieldGroupType, type WorkflowField } from '../../models/workflow-field';

export interface FieldValue {
  fieldId: string;
  fieldGroup: string;
  value: string;
  type: string;
}

@Component({
  selector: 'cc-field-value-setter',
  standalone: true,
  imports: [SelectComponent, FormsModule, NgxMaskDirective, DatePickerComponent, TranslateModule],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './field-value-setter.component.html',
  styleUrl: './field-value-setter.component.scss',
})
export class FieldValueSetterComponent implements OnChanges, OnInit {
  @Input() disabled = false;
  @Input() fields: WorkflowField[] = [];
  @Input() availableFields: WorkflowField[] = [];
  @Input() showErrors = false;
  @Input() value: FieldValue;

  valueChange = output<FieldValue>();

  public fieldOptions: SelectGroup[] = [];
  public valueOptions: SelectOption[] = [];
  private _selectedField: WorkflowField;

  public get selectedField(): WorkflowField {
    return this._selectedField;
  }

  public set selectedField(value: WorkflowField) {
    this._selectedField = value;
    const newValue = {
      ...this.fieldValue,
      fieldId: value.fieldId,
      fieldGroup: value.fieldGroup,
      type: value.controlType,
      value: '', // Reset value when field changes
    };

    this.rebuildValueOptions(newValue);
    this.fieldValue = newValue;
  }

  private _fieldValue: FieldValue;

  get fieldValue(): FieldValue {
    return this._fieldValue;
  }

  private set fieldValue(value: FieldValue) {
    this._fieldValue = value;
    this.valueChange.emit(value);
  }

  public get selectedValue(): string {
    return this.fieldValue?.value;
  }

  public set selectedValue(value: string) {
    this.fieldValue = {
      ...this.fieldValue,
      value: value,
    };
  }

  public ngOnInit(): void {
    if (!this.value) {
      this._fieldValue = { fieldId: '', fieldGroup: '', value: '', type: '' };
      return;
    }

    this._fieldValue = { ...this.value };
    this.fieldOptions = this.getFieldGroups(this.fields);

    if (this.fieldValue.fieldId) {
      this.findAndSetSelectedField();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.fields?.currentValue) {
      this.fieldOptions = this.getFieldGroups(this.fields);
      if (this.fieldValue?.fieldId) {
        this.findAndSetSelectedField();
      }
    }

    if (changes.value?.currentValue) {
      this._fieldValue = { ...changes.value.currentValue };
      if (this.fieldValue?.fieldId && this.fields?.length) {
        this.findAndSetSelectedField();
      }
    }
  }

  private findAndSetSelectedField(): void {
    this.fieldOptions = this.getFieldGroups(this.fields);

    for (const group of this.fieldOptions) {
      const field = group.options.find(
        (opt) => opt.fieldId === this.fieldValue.fieldId && opt.fieldGroup === this.fieldValue.fieldGroup,
      );
      if (field) {
        this._selectedField = field as WorkflowField;
        this.rebuildValueOptions(this.fieldValue);
        break;
      }
    }
  }

  private getFieldGroups(fields: WorkflowField[]): SelectGroup[] {
    const fieldsToUse = this.availableFields?.length ? this.availableFields : fields;
    const publicFields = [];
    const privateFields = [];
    const configFields = [];
    const propertyFields = [];
    const logicFields = [];
    const businessObjectFields = [];

    for (const field of fieldsToUse) {
      switch (field.fieldGroup) {
        case FieldGroupType.Logic: {
          logicFields.push(field);
          break;
        }
        case FieldGroupType.Public: {
          publicFields.push(field);
          break;
        }
        case FieldGroupType.Private: {
          privateFields.push(field);
          break;
        }
        case FieldGroupType.Confidential: {
          configFields.push(field);
          break;
        }
        case FieldGroupType.Proprietary: {
          propertyFields.push(field);
          break;
        }
        case FieldGroupType.BusinessObject: {
          businessObjectFields.push(field);
          break;
        }
        default: {
          break;
        }
      }
    }

    const groups = [];

    if (logicFields.length > 0) {
      groups.push({
        label: 'decisionModal.fieldGroups.logic',
        options: logicFields,
      });
    }

    if (publicFields.length > 0) {
      groups.push({
        label: 'decisionModal.fieldGroups.public',
        options: publicFields,
      });
    }

    if (privateFields.length > 0) {
      groups.push({
        label: 'decisionModal.fieldGroups.private',
        options: privateFields,
      });
    }

    if (configFields.length > 0) {
      groups.push({
        label: 'decisionModal.fieldGroups.confidential',
        options: configFields,
      });
    }

    if (propertyFields.length > 0) {
      groups.push({
        label: 'decisionModal.fieldGroups.custom',
        options: propertyFields,
      });
    }

    if (businessObjectFields.length > 0) {
      groups.push({
        label: 'decisionModal.fieldGroups.businessObject',
        options: businessObjectFields,
      });
    }

    return groups;
  }

  private rebuildValueOptions(value: FieldValue): void {
    if (!value || !this.selectedField) {
      return;
    }

    if (this.selectedField.options?.length > 0) {
      this.valueOptions = this.selectedField.options
        .sort((a, b) => {
          return a.sortOrder > b.sortOrder ? 1 : -1;
        })
        .map((opt) => {
          return {
            value: opt.key,
            label: opt.title,
          };
        });

      const isSelectedValueExists = this.valueOptions.find((option) => option.value === value.value) !== undefined;
      if (!isSelectedValueExists) {
        value.value = null;
      }
    }
  }
}
