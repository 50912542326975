import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, viewChild } from '@angular/core';

import { WINDOW } from '@clover/core/helpers/global-objects';
import { CdkPortalService } from '@clover/core/services/cdk-portal.service';
import { createSelfAssignmentLink } from '@clover/tasks-v2/tasks-self-assignment/tasks-self-assignment.service';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { ToastType } from '@design/overlays/toast/toast';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { ButtonComponent } from '../../../../../stories/buttons/button/button.component';

@Component({
  selector: 'cc-self-assignable-workflow-link-copy-box',
  standalone: true,
  imports: [ButtonComponent, TooltipDirective],
  templateUrl: './self-assignable-workflow-link-copy-box.component.html',
  styleUrl: './self-assignable-workflow-link-copy-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfAssignableWorkflowLinkCopyBoxComponent {
  linkId = input.required<string>();

  linkContainer = viewChild('linkContainer', { read: ElementRef<HTMLDivElement> });

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  private readonly window = inject(WINDOW);
  private readonly clipboard = inject(Clipboard);
  private readonly portal = inject(CdkPortalService);

  protected readonly workflowLink = computed(() => {
    return `${this.window.location.origin}/${createSelfAssignmentLink(this.linkId())}`;
  });

  copy(): void {
    this.clipboard.copy(this.workflowLink());
    this.portal.presentToast('Link copied to clipboard', ToastType.Info);
  }

  selectText(): void {
    const range = this.window.document.createRange();
    range.selectNodeContents(this.linkContainer().nativeElement);

    const selection = this.window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
}
