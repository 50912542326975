export enum WorkflowStepTypeId {
  Start = 0,
  Finish = 1,
  Decision = 2,
  SendEmail = 3,
  СustomForm = 4,
  InstructionalText = 5,
  TextAcknowledgment = 6,
  FileUpload = 7,
  SendNotification = 8,
  ESign = 10,
  ProductCustomForm = 11,
  ProductDecision = 12,
  ProductSendEmail = 13,
  Approval = 15,
  SetFieldValues = 20,
  ReassignTaskAction = 100,
}
