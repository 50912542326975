import { NgClass } from '@angular/common';
import { Component, Input, type OnInit, ViewEncapsulation, inject, AfterViewInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  type ValidatorFn,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { type SelectOption } from '@core/components/select/select.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { OkModalComponent } from '@core/modals/ok-modal/ok-modal.component';
import { ModalService } from '@core/services/modal.service';
import { ToggleComponent } from '@design/forms/toggle/toggle.component';

import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { MultipleStringInputComponent } from '../../../core/components/multiple-string-input/multiple-string-input.component';
import { RichTextEditorComponent } from '../../../core/components/rich-text-editor/rich-text-editor.component';
import { ScrollableAreaComponent } from '../../../core/components/scrollable-area/scrollable-area.component';
import { SelectComponent } from '../../../core/components/select/select.component';
import { RecipientSelectorComponent } from '../../components/recipient-selector/recipient-selector.component';

@Component({
  selector: 'app-send-email-modal',
  templateUrl: './send-email-modal.component.html',
  styleUrls: ['./send-email-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    ScrollableAreaComponent,
    NgClass,
    FormErrorMessagesComponent,
    RichTextEditorComponent,
    ToggleComponent,
    SelectComponent,
    MultipleStringInputComponent,
    RecipientSelectorComponent,
    TranslateModule,
  ],
})
export class SendEmailModalComponent implements OnInit, AfterViewInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly modalService = inject(ModalService);
  @Input() data: any;
  @Input() workflowType: string;

  public form: UntypedFormGroup;
  public selectedUsers: number[] = [];
  public maxScrollAreaHeight: number;

  public fileHandlingTypes: SelectOption[] = [
    {
      value: 'ExcludedFiles',
      label: 'workflows.controls.fileHandling.options.excludeFiles',
    },
    {
      value: 'IncludeIndividualFiles',
      label: 'workflows.controls.fileHandling.options.includeIndividualFiles',
    },
    {
      value: 'IncludeOneZippedFile',
      label: 'workflows.controls.fileHandling.options.includeOneZipFile',
    },
  ];

  public ngOnInit(): void {
    const data = this.data
      ? this.data
      : {
          shouldTaskDataBeIncluded: false,
        };
    if (Object.keys(data).length > 0 && data.emailBody) {
      const regex = /\[\[\s*(.*?)\s*\]\]/g;
      data.emailBody.html = data.emailBody.html.replace(regex, '<span class="workflow-token">$1</span>');
    }
    this.selectedUsers = data.recipientIds || [];
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(data.name, [Validators.required]),
      description: new UntypedFormControl(data.description, [Validators.required]),
      shouldTaskDataBeIncluded: new UntypedFormControl(data.shouldTaskDataBeIncluded),
      fileHandlingType: new UntypedFormControl(data.fileHandlingType || this.fileHandlingTypes[0].value),
      emailSubject: new UntypedFormControl(data.emailSubject, [Validators.required, Validators.maxLength(40)]),
      emailBody: new UntypedFormControl(data.emailBody, [Validators.required]),
      additionalRecipientEmails: new UntypedFormControl(
        data.additionalRecipientEmails ? data.additionalRecipientEmails : [],
        [this.emailsStringValidator()],
      ),
    });
  }

  // add hook
  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxScrollAreaHeight = this.getMaxScrollAreaHeight();
    }, 0);
  }

  public getMaxScrollAreaHeight(): number {
    const headerHeight = document.querySelector('.workflow-step-modal_header').clientHeight;
    const modalHeaderHeight = document.querySelector('.modal-header').clientHeight;
    return document.body.clientHeight - headerHeight - modalHeaderHeight - 64;
  }

  public save(): void {
    if (this.form.invalid) {
      setTimeout(() => {
        const firstError = document.querySelector('.workflow-step-modal_scroll-area .has-error');
        firstError.scrollIntoView();
      }, 0);

      return;
    }

    const data = this.form.value;

    if (this.selectedUsers.length === 0 && !data.additionalRecipientEmails?.length) {
      this.modalService.open({
        content: OkModalComponent,
        inputs: {
          isError: true,
          title: 'sendEmailNotificationModal.emailIsRequired',
          text: 'sendEmailNotificationModal.emailIsRequiredMsg',
        },
      });
      return;
    }

    data.recipientIds = this.selectedUsers;
    this.activeModal.close(this.form.value);
  }

  private emailsStringValidator(): ValidatorFn {
    return (control: UntypedFormControl): Record<string, any> => {
      const emails = control?.value;

      if (!emails?.length) {
        return;
      }

      const isValid =
        emails.find((email) => {
          // eslint-disable-next-line max-len
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !re.test(String(email).toLowerCase());
        }) === undefined;

      return isValid ? null : { invalid: true };
    };
  }
}
