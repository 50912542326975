import { NgClass } from '@angular/common';
import { Component, Input, type OnInit, ViewEncapsulation, inject, AfterViewInit, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  type AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { map, catchError, of, finalize } from 'rxjs';

import { CustomFieldType } from '@clover/custom-fields/models/custom-field';
import { ContactSelectorComponent } from '@clover/network/components/contact-selector/contact-selector.component';
import { type CompanyContact } from '@clover/network/models/contact';
import { NetworkService } from '@clover/network/network.service';
import { LegacyLoaderComponent } from '@core/components/loader/loader.component';
import { type SelectOption } from '@core/components/select/select.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { EnumService } from '@core/services/enum.service';
import { ModalService } from '@core/services/modal.service';

import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { RichTextEditorComponent } from '../../../core/components/rich-text-editor/rich-text-editor.component';
import { ScrollableAreaComponent } from '../../../core/components/scrollable-area/scrollable-area.component';
import { SelectComponent } from '../../../core/components/select/select.component';
import { TemplateSelectionModalComponent } from '../../modals/template-selection-modal/template-selection-modal.component';
import {
  type EsignTemplate,
  type EsignTemplateCustomField,
  type EsignTemplateAdditionalSignerRole,
  type CustomFieldMapping,
  type AdditionalSignerMapping,
} from '../../models/esign-template';
import { FieldGroupType, type WorkflowField } from '../../models/workflow-field';
import { WorkflowsService } from '../../workflows.service';

// Map of supported DocuSign variable types to our custom field types
const SUPPORTED_FIELD_TYPES = new Map<string, CustomFieldType[]>([
  ['text', [CustomFieldType.Text, CustomFieldType.Number, CustomFieldType.Date]],
]);

@Component({
  selector: 'app-docusign-modal',
  templateUrl: './docusign-modal.component.html',
  styleUrls: ['./docusign-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    ScrollableAreaComponent,
    NgClass,
    FormErrorMessagesComponent,
    RichTextEditorComponent,
    SelectComponent,
    ContactSelectorComponent,
    TranslateModule,
    LegacyLoaderComponent,
  ],
})
export class DocusignModalComponent implements OnInit, AfterViewInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly modalService = inject(ModalService);
  private readonly enumService = inject(EnumService);
  private readonly workflowsService = inject(WorkflowsService);
  private readonly networkService = inject(NetworkService);
  private readonly destroyRef = inject(DestroyRef);

  @Input() data: any;
  @Input() workflowType: string;

  public form: UntypedFormGroup;
  public fileCategories: SelectOption[];
  public templatesControl: AbstractControl;
  public customFieldMappingsControl: AbstractControl;
  public additionalSignersControl: AbstractControl;
  public maxScrollAreaHeight: number;
  public availableFields: WorkflowField[] = [];
  public isLoadingFields = false;
  public isLoadingContacts = false;

  public templateErrors = {
    required: 'docusignModal.errors.templatesRequired',
    fileCategoriesRequired: 'docusignModal.errors.fileCategoriesRequired',
    requiredFieldsMapped: 'docusignModal.errors.requiredFieldsMapped',
    requiredSignersMapped: 'docusignModal.errors.requiredSignersMapped',
  };

  public get selectedTemplates(): EsignTemplate[] {
    return this.templatesControl.value;
  }

  public get customVariables(): EsignTemplateCustomField[] {
    return this.selectedTemplates
      .flatMap((template) => template.customFields || [])
      .filter((field, index, self) => index === self.findIndex((f) => f.name === field.name));
  }

  public get additionalSignerRoles(): EsignTemplateAdditionalSignerRole[] {
    return this.selectedTemplates
      .flatMap((template) => template.additionalSignerRoles || [])
      .filter((role, index, self) => index === self.findIndex((r) => r.name === role.name))
      .sort((a, b) => a.order - b.order);
  }

  public ngOnInit(): void {
    const data = this.data ? this.data : {};
    if (Object.keys(data).length > 0) {
      const regex = /\[\[\s*(.*?)\s*\]\]/g;
      data.text.html = data.text.html.replace(regex, '<span class="workflow-token">$1</span>');
    }

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(data.name, [Validators.required]),
      description: new UntypedFormControl(data.description, [Validators.required]),
      templates: new UntypedFormControl(data.templates || [], [Validators.required]),
      text: new UntypedFormControl(data.text, [Validators.required]),
    });

    this.templatesControl = this.form.get('templates');

    this.templatesControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.checkCategoriesSelection();
      this.validateTemplateFields();
    });

    if (data.templates?.length) {
      this.restoreTemplateContacts();
    }

    this.enumService
      .getFileCategories()
      .then((res) => (this.fileCategories = res))
      .catch(() => {});

    this.loadAvailableFields();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxScrollAreaHeight = this.getMaxScrollAreaHeight();
    }, 0);
  }

  public getMaxScrollAreaHeight(): number {
    const headerHeight = document.querySelector('.workflow-step-modal_header').clientHeight;
    const modalHeaderHeight = document.querySelector('.modal-header').clientHeight;
    return document.body.clientHeight - headerHeight - modalHeaderHeight - 64;
  }

  checkCategoriesSelection(): void {
    if (this.selectedTemplates.length === 0) {
      return;
    }

    const areCategoriesSelected = !this.selectedTemplates.find((temp) => !temp.fileCategory);

    if (!areCategoriesSelected) {
      this.templatesControl.setErrors({ fileCategoriesRequired: true });
    } else {
      this.templatesControl.setErrors(null);
    }
  }

  public onCustomFieldMappingChange(templateId: number, customFieldName: string, field: WorkflowField | null): void {
    const templates = [...this.selectedTemplates];
    const template = templates.find((t) => t.id === templateId);
    if (!template) return;

    template.customFieldMappings = template.customFieldMappings || [];
    const existingIndex = template.customFieldMappings.findIndex((m) => m.customFieldName === customFieldName);

    if (!field) {
      if (existingIndex !== -1) {
        template.customFieldMappings.splice(existingIndex, 1);
      }
    } else {
      const mapping: CustomFieldMapping = {
        customFieldName,
        fieldId: field.fieldId,
        fieldGroup: field.fieldGroup,
      };

      if (existingIndex !== -1) {
        template.customFieldMappings[existingIndex] = mapping;
      } else {
        template.customFieldMappings.push(mapping);
      }
    }

    this.templatesControl.setValue(templates);
    this.validateTemplateFields();
  }

  public onAdditionalSignerChange(templateId: number, role: string, contact: CompanyContact | null): void {
    const templates = [...this.selectedTemplates];
    const template = templates.find((t) => t.id === templateId);
    if (!template) return;

    template.additionalSigners = template.additionalSigners || [];
    const existingIndex = template.additionalSigners.findIndex((s) => s.role === role);

    if (!contact) {
      if (existingIndex !== -1) {
        template.additionalSigners.splice(existingIndex, 1);
      }
    } else {
      const mapping: AdditionalSignerMapping = {
        role,
        contactId: contact.id,
        contact,
      };

      if (existingIndex !== -1) {
        template.additionalSigners[existingIndex] = mapping;
      } else {
        template.additionalSigners.push(mapping);
      }
    }

    this.templatesControl.setValue(templates);
    this.validateTemplateFields();
  }

  public getSelectedContactForRole(template: EsignTemplate, role: string): CompanyContact | null {
    const mapping = template.additionalSigners?.find((s) => s.role === role);
    return mapping?.contact || null;
  }

  public hasSignerMapping(template: EsignTemplate, role: string): boolean {
    return template.additionalSigners?.some((s) => s.role === role) || false;
  }

  public getSelectedFieldForMapping(template: EsignTemplate, customFieldName: string): WorkflowField | null {
    const mapping = template.customFieldMappings?.find((m) => m.customFieldName === customFieldName);
    if (!mapping) return null;

    return this.availableFields.find((f) => f.fieldId === mapping.fieldId && f.fieldGroup === mapping.fieldGroup);
  }

  public hasMapping(template: EsignTemplate, variableName: string): boolean {
    return template.customFieldMappings?.some((m) => m.customFieldName === variableName) || false;
  }

  public save(): void {
    this.validateTemplateFields();

    if (
      this.form.invalid ||
      this.templatesControl.errors?.requiredFieldsMapped ||
      this.templatesControl.errors?.requiredSignersMapped
    ) {
      setTimeout(() => {
        const firstError = document.querySelector('.workflow-step-modal_scroll-area .has-error');
        firstError?.scrollIntoView();
      }, 0);
      return;
    }

    const areCategoriesSelected = !this.selectedTemplates.find((temp) => !temp.fileCategory);
    if (!areCategoriesSelected) {
      this.templatesControl.setErrors({ ...this.templatesControl.errors, fileCategoriesRequired: true });
      setTimeout(() => {
        const firstError = document.querySelector('.workflow-step-modal_scroll-area .has-error');
        firstError?.scrollIntoView();
      }, 0);
      return;
    }

    const result = {
      ...this.form.value,
      templates: this.selectedTemplates.map((template) => ({
        ...template,
        additionalSigners: template.additionalSigners?.map(({ role, contactId }) => ({ role, contactId })),
      })),
    };

    this.activeModal.close(result);
  }

  public selectTemplates(): void {
    this.modalService
      .open({
        content: TemplateSelectionModalComponent,
        options: {
          size: 'xl',
        },
      })
      .result.then((templates: EsignTemplate[]) => {
        this.templatesControl.setValue(templates);
      })
      .catch(() => {});
  }

  public removeTemplate(template: EsignTemplate): void {
    const templates = this.selectedTemplates.filter((temp) => temp.id !== template.id);
    this.templatesControl.setValue(templates);
  }

  private loadAvailableFields(): void {
    this.isLoadingFields = true;

    const businessObjectFields = this.workflowsService.businessObjects
      .map((businessObject) => {
        return businessObject.fields.map((field) => ({
          fieldId: field.id,
          controlType: field.controlType,
          type: field.type,
          label: `${businessObject.id} ${field.title}`,
          fieldGroup: FieldGroupType.BusinessObject,
          options: field.options?.items.map((opt) => ({
            key: opt,
            title: opt,
          })),
        }));
      })
      .flatMap((fields) => fields);

    const fields = [
      ...this.workflowsService.logicFields.map((field) => ({
        fieldId: field.id,
        controlType: field.controlType,
        type: field.type,
        label: field.title,
        fieldGroup: FieldGroupType.Logic,
        options: field.options?.items.map((opt) => ({
          key: opt,
          title: opt,
        })),
      })),
      ...businessObjectFields,
    ];

    this.workflowsService
      .getCompanyFields()
      .then((res) => {
        if (res.publicFields?.length > 0)
          fields.push(...res.publicFields.map((field) => this.mapCustomField(field, FieldGroupType.Public)));

        if (res.privateFields?.length > 0)
          fields.push(...res.privateFields.map((field) => this.mapCustomField(field, FieldGroupType.Private)));

        if (res.confidentialFields?.length > 0)
          fields.push(
            ...res.confidentialFields.map((field) => this.mapCustomField(field, FieldGroupType.Confidential)),
          );

        if (res.proprietaryFields?.length > 0)
          fields.push(
            ...res.proprietaryFields
              .filter((field) => field.status !== 'Inactive')
              .map((field) => this.mapCustomField(field, FieldGroupType.Proprietary)),
          );

        this.availableFields = fields.filter((field) => this.isFieldTypeSupported(field.type));
      })
      .finally(() => {
        this.isLoadingFields = false;
      });
  }

  private restoreTemplateContacts(): void {
    const templates = this.selectedTemplates;
    const allSignerIds = new Set<number>();

    templates.forEach((template) => {
      template.additionalSigners?.forEach((signer) => {
        if (!signer.contact) allSignerIds.add(signer.contactId);
      });
    });

    if (!allSignerIds.size) return;

    this.isLoadingContacts = true;

    this.networkService
      .searchCompanyContacts({
        AssociatedUserIdsToInclude: Array.from(allSignerIds),
      })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((res) => {
          if (res.data?.length > 0) {
            const updatedTemplates = templates.map((template) => ({
              ...template,
              additionalSigners: template.additionalSigners?.map((signer) => {
                const contactData = res.data.find((d) => d.contact.id === signer.contactId);
                return contactData
                  ? {
                      ...signer,
                      contact: contactData.contact,
                    }
                  : signer;
              }),
            }));
            this.templatesControl.setValue(updatedTemplates);
          }
        }),
        catchError(() => of(null)),
        finalize(() => {
          this.isLoadingContacts = false;
        }),
      )
      .subscribe();
  }

  private validateTemplateFields(): void {
    const templates = this.selectedTemplates;
    let hasRequiredFieldsError = false;
    let hasRequiredSignersError = false;

    for (const template of templates) {
      // Check required custom fields
      const requiredVariables = template.customFields?.filter((v) => v.required) || [];
      if (requiredVariables.length > 0) {
        const allRequiredFieldsMapped = requiredVariables.every((v) =>
          template.customFieldMappings?.some((m) => m.customFieldName === v.name),
        );
        if (!allRequiredFieldsMapped) {
          hasRequiredFieldsError = true;
        }
      }

      // Check required signers
      const requiredSignerRoles = template.additionalSignerRoles || [];
      if (requiredSignerRoles.length > 0) {
        const allSignersMapped = requiredSignerRoles.every((role) =>
          template.additionalSigners?.some((s) => s.role === role.name),
        );
        if (!allSignersMapped) {
          hasRequiredSignersError = true;
        }
      }
    }

    const currentErrors = this.templatesControl.errors ? { ...this.templatesControl.errors } : {};

    if (hasRequiredFieldsError) {
      currentErrors.requiredFieldsMapped = true;
    } else {
      delete currentErrors.requiredFieldsMapped;
    }

    if (hasRequiredSignersError) {
      currentErrors.requiredSignersMapped = true;
    } else {
      delete currentErrors.requiredSignersMapped;
    }

    this.templatesControl.setErrors(Object.keys(currentErrors).length > 0 ? currentErrors : null);
  }

  private mapCustomField(field: any, fieldGroup: FieldGroupType): WorkflowField {
    return {
      fieldId: field.key,
      controlType: field.controlType,
      type: field.type,
      label: field.title,
      fieldGroup,
      options: field.options,
    };
  }

  private isFieldTypeSupported(fieldType: CustomFieldType): boolean {
    return Array.from(SUPPORTED_FIELD_TYPES.values()).some((types) => types.includes(fieldType));
  }
}
