<div>
  <div>{{ 'decisionModal.strings.field' | translate }}</div>
  <div>
    <app-select
      [(ngModel)]="selectedField"
      [optionGroups]="fieldOptions"
      [returnFullOption]="true"
      placeholder="decisionModal.placeholders.selectField"
      [class.has-error]="showErrors && !selectedField"
      [disabled]="disabled"
    >
    </app-select>
    @if (showErrors && !selectedField) {
      <div class="custom-control-error">
        {{ 'criteriaSelector.fieldRequired' | translate }}
      </div>
    }
  </div>
</div>

@if (selectedField) {
  <div>
    <div>{{ 'decisionModal.strings.value' | translate }}</div>
    <div>
      @if (
        selectedField.controlType !== 'SingleSelectList' &&
        selectedField.controlType !== 'MultiSelectList' &&
        selectedField.controlType !== 'Date' &&
        selectedField.controlType !== 'Number'
      ) {
        <input
          placeholder="{{ 'decisionModal.placeholders.enterValue' | translate }}"
          [(ngModel)]="selectedValue"
          [class.has-error]="showErrors && (!selectedValue || selectedValue.length === 0)"
          [disabled]="disabled"
        />
      }
      @if (selectedField.controlType === 'Number') {
        <input
          mask="0*.0*"
          [dropSpecialCharacters]="false"
          placeholder="{{ 'decisionModal.placeholders.enterValue' | translate }}"
          [(ngModel)]="selectedValue"
          [class.has-error]="showErrors && (!selectedValue || selectedValue.length === 0)"
          [disabled]="disabled"
        />
      }
      @if (selectedField.controlType === 'SingleSelectList' || selectedField.controlType === 'MultiSelectList') {
        <app-select
          [(ngModel)]="selectedValue"
          [options]="valueOptions"
          placeholder="decisionModal.placeholders.selectValue"
          [class.has-error]="showErrors && !selectedValue"
          [disabled]="disabled"
        ></app-select>
      }
      @if (selectedField.controlType === 'Date') {
        <app-date-picker
          [class.has-error]="showErrors && !selectedValue"
          placeholder="decisionModal.placeholders.selectValue"
          [(ngModel)]="selectedValue"
          [disabled]="disabled"
        >
        </app-date-picker>
      }
      @if (showErrors && (!selectedValue || selectedValue.length === 0)) {
        <div class="custom-control-error">
          {{ 'criteriaSelector.valueRequired' | translate }}
        </div>
      }
    </div>
  </div>
}
