import { NgStyle, NgClass } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { CustomFieldType } from '@clover/custom-fields/models/custom-field';
import { ScrollableAreaComponent } from '@core/components/scrollable-area/scrollable-area.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { LegacyLoaderComponent } from '../../../core/components/loader/loader.component';
import {
  FieldValueSetterComponent,
  type FieldValue,
} from '../../components/field-value-setter/field-value-setter.component';
import { WorkflowField, FieldGroupType } from '../../models/workflow-field';
import { WorkflowsService } from '../../workflows.service';

@Component({
  selector: 'cc-set-field-values-modal',
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    NgStyle,
    LegacyLoaderComponent,
    ScrollableAreaComponent,
    NgClass,
    FormErrorMessagesComponent,
    TranslateModule,
    FieldValueSetterComponent,
  ],
  templateUrl: './set-field-values-modal.component.html',
  styleUrl: './set-field-values-modal.component.scss',
})
export class SetFieldValuesModalComponent implements OnInit {
  @ViewChild('scrollArea') scrollArea: ScrollableAreaComponent;
  @Input() data: any;

  public form: UntypedFormGroup;
  public fields: WorkflowField[] = [];
  public fieldValues: FieldValue[] = [];
  public maxScrollAreaHeight = 'calc(90vh - 200px)';

  public get hasAvailableFields(): boolean {
    const usedFieldIds = new Set(this.fieldValues.map((fv) => fv.fieldId).filter((id) => id));
    return this.fields.some((field) => !usedFieldIds.has(field.fieldId));
  }

  public getAvailableFields(currentFieldId: string | null): WorkflowField[] {
    const usedFieldIds = new Set(this.fieldValues.map((fv) => fv.fieldId).filter((id) => id && id !== currentFieldId));
    return this.fields.filter((field) => !usedFieldIds.has(field.fieldId));
  }

  constructor(
    public activeModal: NgbActiveModal,
    private workflowsService: WorkflowsService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadFields();
  }

  private initForm(): void {
    const data = this.data || {};
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(data.name, [Validators.required]),
      description: new UntypedFormControl(data.description, [Validators.required]),
    });

    this.fieldValues = data.fieldValues?.length
      ? data.fieldValues.map((fv) => ({ ...fv }))
      : [{ fieldId: '', fieldGroup: '', value: '', type: '' }];
  }

  public addFieldValue(): void {
    if (this.hasAvailableFields) {
      this.fieldValues.push({ fieldId: '', fieldGroup: '', value: '', type: '' });
    }
  }

  public removeFieldValue(index: number): void {
    if (this.fieldValues.length > 1) {
      this.fieldValues.splice(index, 1);
    }
  }

  public onFieldValueChange(value: FieldValue, index: number): void {
    this.fieldValues[index] = value;
  }

  public save(): void {
    if (this.form.valid && this.areFieldValuesValid()) {
      const result = {
        ...this.form.value,
        fieldValues: this.fieldValues.filter((fv) => fv.fieldId && fv.value),
      };
      this.activeModal.close(result);
    }
  }

  private areFieldValuesValid(): boolean {
    return this.fieldValues.some((fv) => fv.fieldId && fv.value);
  }

  private loadFields(): void {
    const businessObjectFields = this.workflowsService.businessObjects
      .map((businessObject) => {
        return businessObject.fields.map((field) => ({
          fieldId: field.id,
          controlType: field.controlType,
          type: field.type,
          label: `${businessObject.id} ${field.title}`,
          fieldGroup: FieldGroupType.BusinessObject,
          options: field.options?.items.map((opt) => ({
            key: opt,
            title: opt,
          })),
        }));
      })
      .flatMap((fields) => fields);

    const fields = [
      ...this.workflowsService.logicFields.map((field) => ({
        fieldId: field.id,
        controlType: field.controlType,
        type: field.type,
        label: field.title,
        fieldGroup: FieldGroupType.Logic,
        options: field.options?.items.map((opt) => ({
          key: opt,
          title: opt,
        })),
      })),
      ...businessObjectFields,
    ];

    this.workflowsService.getCompanyFields().then((res) => {
      if (res.publicFields?.length > 0) {
        fields.push(...res.publicFields.map((field) => this.MapField(field, FieldGroupType.Public)));
      }

      if (res.privateFields?.length > 0) {
        fields.push(...res.privateFields.map((field) => this.MapField(field, FieldGroupType.Private)));
      }

      if (res.confidentialFields?.length > 0) {
        fields.push(...res.confidentialFields.map((field) => this.MapField(field, FieldGroupType.Confidential)));
      }

      if (res.proprietaryFields?.length > 0) {
        fields.push(
          ...res.proprietaryFields
            .filter((field) => field.status !== 'Inactive')
            .map((field) => this.MapField(field, FieldGroupType.Proprietary)),
        );
      }

      this.fields = fields.filter((field) => {
        return (
          field.type === CustomFieldType.Text ||
          field.type === CustomFieldType.Number ||
          field.type === CustomFieldType.Date
        );
      });
    });
  }

  private MapField(field: any, fieldGroup: FieldGroupType): WorkflowField {
    return {
      fieldId: field.key,
      controlType: field.controlType,
      type: field.type,
      label: field.title,
      fieldGroup,
      options: field.options,
    };
  }
}
