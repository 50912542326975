<div class="modal-header">
  <button
    [appFocusable]
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>
</div>

<div class="modal-body">
  <form
    [formGroup]="form"
    class="workflow-step-modal_form"
    #ngForm="ngForm"
    (ngSubmit)="save()"
  >
    <div class="workflow-step-modal_header">
      <div>
        <span>{{ 'common.labels.step' | translate }}: </span>
        <span>{{ 'reassignTaskActionModal.title' | translate }}</span>
      </div>

      <div>
        <div>{{ 'reassignTaskActionModal.titleMsg' | translate }}</div>

        <button
          type="submit"
          class="btn btn-primary submit-btn"
        >
          {{ 'common.buttons.save&close' | translate }}
        </button>
      </div>
    </div>

    <div class="workflow-step-modal_scroll-area">
      <scrollable-area [maxHeight]="maxScrollAreaHeight">
        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('name').invalid,
          }"
        >
          <label
            class="required"
            for="actionName"
          >
            {{ 'workflows.controls.actionName.label' | translate }}
          </label>

          <input
            formControlName="name"
            id="actionName"
            placeholder="{{ 'workflows.controls.actionName.placeholder' | translate }}"
          />

          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('name')"
          />
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('description').invalid,
          }"
        >
          <label
            class="required"
            for="description"
          >
            {{ 'workflows.controls.actionDescription.label' | translate }}
          </label>

          <textarea
            formControlName="description"
            id="description"
            placeholder="{{ 'workflows.controls.actionDescription.placeholder' | translate }}"
          >
          </textarea>

          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('description')"
          />
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('assignmentType').invalid,
          }"
        >
          <label
            class="required"
            for="assignmentType"
          >
            {{ 'workflows.controls.assignmentType.label' | translate }}
          </label>

          <app-select
            formControlName="assignmentType"
            id="assignmentType"
            placeholder="workflows.controls.assignmentType.placeholder"
            [options]="assignmentTypes"
          />

          <form-error-messages
            [showErrors]="ngForm.submitted"
            [control]="form.get('assignmentType')"
          />
        </div>

        @if (form.get('reassignTo')?.enabled) {
          <div class="form-group">
            @if (contactLoading()) {
              <loader />
            } @else {
              <label for="reassignTo">
                {{ 'workflows.controls.reassignTo.label' | translate }}
              </label>

              <contact-selector
                [ngModel]="selectedContact()"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onContactChange($event)"
                [placeholder]="'workflows.controls.reassignTo.placeholder' | translate"
                id="reassignTo"
              />

              <form-error-messages
                [showErrors]="ngForm.submitted"
                [control]="form.get('reassignTo')"
              />
            }
          </div>
        }
      </scrollable-area>
    </div>
  </form>
</div>
