import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgScrollbar } from 'ngx-scrollbar';
import { catchError, of, Subject, switchMap, tap } from 'rxjs';

import { TaskAssignmentAudienceService } from '@clover/conversations-v4/tasks/task-assignment/audience.service';
import type { TaskAudienceContact } from '@clover/conversations-v4/tasks/task-assignment/task-assignment.model';
import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import { LoadingStatus, type NextPaginatedLoadingStatus } from '@clover/core/helpers/loading';
import { EMPTY_PAGING, type OffsetPagingWrapper } from '@clover/core/helpers/paging';
import { CdkPortalService } from '@clover/core/services/cdk-portal.service';
import { UserService } from '@clover/core/services/user.service';
import { WorkflowsService } from '@clover/workflows-v2/state/workflows.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { injectDialogData } from '@design/misc/dialog.helpers';
import { ToastType } from '@design/overlays/toast/toast';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TdComponent } from '@design/table/td/td.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

import { AssignmentLinkConfiguration } from './../../state/workflows-state.model';
import { RadioComponent } from '../../../../stories/forms/radio/radio.component';
import { ToggleComponent } from '../../../../stories/forms/toggle/toggle.component';
import { LoaderComponent } from '../../../../stories/misc/loader/loader.component';
import { UserAvatarComponent } from '../../../../stories/misc/user-avatar/user-logo.component';
import { TableComponent } from '../../../../stories/table/table.component';
import { SelfAssignableWorkflowLinkCopyBoxComponent } from '../self-assignable-workflow-link-preview-dialog/self-assignable-workflow-link-copy-box/self-assignable-workflow-link-copy-box.component';
import {
  SelfAssignableWorkflowLinkPreviewDialogComponent,
  type SelfAssignableWorkflowLinkPreviewDialogData,
  type SelfAssignableWorkflowLinkPreviewDialogResult,
} from '../self-assignable-workflow-link-preview-dialog/self-assignable-workflow-link-preview-dialog.component';

export interface SelfAssignableWorkflowSettingsDialogData {
  workflowId: number;
}

export type SelfAssignableWorkflowSettingsDialogResult = never;

@Component({
  standalone: true,
  imports: [
    ButtonComponent,
    InfiniteScrollDirective,
    NgScrollbar,
    AutoAnimateDirective,
    TranslateModule,
    ToggleComponent,
    LoaderComponent,
    ReactiveFormsModule,
    TableComponent,
    ThComponent,
    TrComponent,
    TdComponent,
    RadioComponent,
    UserAvatarComponent,
    TooltipDirective,
    SelfAssignableWorkflowLinkCopyBoxComponent,
  ],
  templateUrl: './self-assignable-workflow-settings-dialog.component.html',
  styleUrl: './self-assignable-workflow-settings-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfAssignableWorkflowSettingsDialogComponent {
  protected readonly dialogData = injectDialogData<SelfAssignableWorkflowSettingsDialogData>();
  protected readonly dialogRef: DialogRef<SelfAssignableWorkflowSettingsDialogResult | undefined> = inject(
    DialogRef<SelfAssignableWorkflowSettingsDialogResult | undefined>,
  );

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  protected readonly assignmentLinkForm = new FormGroup(
    {
      enabled: new FormControl<boolean | undefined>(undefined),
      assignerId: new FormControl<number | undefined>(undefined),
    },
    {
      validators: [
        (control) => {
          const enabled = control.get('enabled')?.value;
          const assignerId = control.get('assignerId')?.value;

          if (enabled && !assignerId) return { assignerIdRequired: true };
          return null;
        },
      ],
    },
  );

  protected readonly assignmentLinkConfiguration = signal<AssignmentLinkConfiguration | undefined>(undefined);
  protected readonly assignmentLinkConfigurationLoadingStatus = signal<LoadingStatus>('void');

  protected readonly contacts = signal<OffsetPagingWrapper<TaskAudienceContact>>(EMPTY_PAGING);
  protected readonly contactsLoadingStatus = signal<NextPaginatedLoadingStatus>('void');
  protected readonly loadNextContacts$ = new Subject<void>();

  protected readonly saving = signal<boolean>(false);

  protected readonly loadingStatus = computed<LoadingStatus>(() => {
    const configurationLoadingStatus = this.assignmentLinkConfigurationLoadingStatus();
    const contactsLoadingStatus = this.contactsLoadingStatus();

    if (configurationLoadingStatus === 'void' || contactsLoadingStatus === 'void') return 'void';
    if (configurationLoadingStatus === 'loading' || contactsLoadingStatus === 'loading') return 'loading';
    if (configurationLoadingStatus === 'error' || contactsLoadingStatus === 'error') return 'error';

    return 'loaded';
  });

  private readonly workflowsService = inject(WorkflowsService);
  private readonly taskAssignmentAudienceService = inject(TaskAssignmentAudienceService);
  private readonly userService = inject(UserService);
  private readonly dialog = inject(Dialog);
  private readonly portal = inject(CdkPortalService);
  private readonly destroyRef = inject(DestroyRef);

  get linkEnabledControl(): FormControl<boolean | undefined> {
    return this.assignmentLinkForm.controls.enabled;
  }

  get assignerIdControl(): FormControl<number | undefined> {
    return this.assignmentLinkForm.controls.assignerId;
  }

  constructor() {
    this.loadAssignmentLinkConfiguration();
    this.initContacts();
  }

  save(): void {
    if (this.assignmentLinkForm.invalid) return;
    this.saving.set(true);

    this.workflowsService
      .changeSelfAssignmentConfiguration(this.dialogData().workflowId, {
        enabled: this.linkEnabledControl.value,
        assignerId: this.linkEnabledControl.value ? this.assignerIdControl.value : undefined,
      })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(({ enabled, id }) => {
          this.saving.set(false);

          if (enabled) {
            this.dialog.open<
              SelfAssignableWorkflowLinkPreviewDialogResult,
              SelfAssignableWorkflowLinkPreviewDialogData
            >(SelfAssignableWorkflowLinkPreviewDialogComponent, {
              data: {
                linkId: id,
              },
            });
          } else {
            this.portal.presentToast('Self-assignment link disabled', ToastType.Success);
          }

          this.dialogRef.close();
        }),
      )
      .subscribe();
  }

  private loadAssignmentLinkConfiguration(): void {
    this.assignmentLinkConfigurationLoadingStatus.set('loading');

    this.workflowsService
      .getAssignmentLinkConfiguration(this.dialogData().workflowId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((assignmentLinkConfiguration) => {
          this.assignmentLinkConfiguration.set(assignmentLinkConfiguration);
          this.assignmentLinkForm.patchValue({
            enabled: assignmentLinkConfiguration?.enabled,
            assignerId: assignmentLinkConfiguration?.assignerId || this.userService.userProfile.id,
          });
          this.assignmentLinkConfigurationLoadingStatus.set('loaded');
        }),
        catchError(() => {
          this.assignmentLinkConfigurationLoadingStatus.set('error');
          return of(undefined);
        }),
      )
      .subscribe();
  }

  private initContacts(): void {
    const myCompanyId = this.userService.userCompany.id;

    of(myCompanyId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        // Loading initial contacts
        tap(() => this.contactsLoadingStatus.set('loading')),
        switchMap((companyId) => this.taskAssignmentAudienceService.searchContacts(companyId, '', { limit: 30 })),
        tap((contacts) => {
          this.contacts.set(contacts);
          this.contactsLoadingStatus.set('loaded');
        }),
        // Loading next contacts
        switchMap(() => this.loadNextContacts$),
        tap(() => this.contactsLoadingStatus.set('loading-next')),
        switchMap(() =>
          this.taskAssignmentAudienceService.searchContacts(myCompanyId, '', {
            limit: 30,
            offset: this.contacts().count,
          }),
        ),
        tap((contacts) => {
          this.contacts.update((prev) => ({
            ...contacts,
            data: [...prev.data, ...contacts.data],
            count: prev.count + contacts.data.length,
          }));
          this.contactsLoadingStatus.set('loaded');
        }),
        catchError((error) => {
          this.contactsLoadingStatus.set('error');
          console.error(error);
          return of(EMPTY_PAGING);
        }),
      )
      .subscribe();
  }
}
