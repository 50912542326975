import { NgClass } from '@angular/common';
import {
  Component,
  Input,
  type OnInit,
  ViewEncapsulation,
  inject,
  AfterViewInit,
  DestroyRef,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, finalize, of, startWith, tap } from 'rxjs';

import type { CompanyContact } from '@clover/network/models/contact';
import { NetworkService } from '@clover/network/network.service';
import { type SelectOption } from '@core/components/select/select.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { LegacyLoaderComponent } from '../../../core/components/loader/loader.component';
import { ScrollableAreaComponent } from '../../../core/components/scrollable-area/scrollable-area.component';
import { SelectComponent } from '../../../core/components/select/select.component';
import { ContactSelectorComponent } from '../../../network/components/contact-selector/contact-selector.component';

export enum AssignmentType {
  Internal = 'Internal',
  External = 'External',
}

@Component({
  selector: 'app-reassign-task-modal',
  templateUrl: './reassign-task-modal.component.html',
  styleUrls: ['./reassign-task-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    ScrollableAreaComponent,
    NgClass,
    FormErrorMessagesComponent,
    SelectComponent,
    TranslateModule,
    ContactSelectorComponent,
    LegacyLoaderComponent,
  ],
})
export class ReassignTaskModalComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Input() workflowType: string;

  maxScrollAreaHeight: number;
  selectedContact = signal<CompanyContact | null>(null);
  contactLoading = signal(false);

  protected readonly activeModal = inject(NgbActiveModal);

  private readonly networkService = inject(NetworkService);
  private readonly destroyRef = inject(DestroyRef);

  form: UntypedFormGroup;
  assignmentTypes: SelectOption[] = [
    {
      value: AssignmentType.Internal,
      label: 'workflows.controls.assignmentType.options.internal',
    },
    {
      value: AssignmentType.External,
      label: 'workflows.controls.assignmentType.options.external',
    },
  ];

  ngOnInit(): void {
    const data = this.data ? this.data : {};

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(data.name, [Validators.required]),
      description: new UntypedFormControl(data.description, [Validators.required]),
      assignmentType: new UntypedFormControl(data.assignmentType || this.assignmentTypes[0].value),
      reassignTo: new UntypedFormGroup({
        contactId: new UntypedFormControl(data.reassignTo?.contactId),
      }),
    });

    this.restoreContactData();

    const assignmentTypeControl = this.form.get('assignmentType');
    assignmentTypeControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(assignmentTypeControl.value),
        tap((assignmentType) => {
          const isInternal = assignmentType === AssignmentType.Internal;

          if (isInternal) {
            this.form.get('reassignTo').enable();
          } else {
            this.form.get('reassignTo').disable();
          }
        }),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxScrollAreaHeight = this.getMaxScrollAreaHeight();
    }, 0);
  }

  getMaxScrollAreaHeight(): number {
    const headerHeight = document.querySelector('.workflow-step-modal_header').clientHeight;
    const modalHeaderHeight = document.querySelector('.modal-header').clientHeight;

    return document.body.clientHeight - headerHeight - modalHeaderHeight - 64;
  }

  save(): void {
    if (this.form.invalid) {
      setTimeout(() => {
        const firstError = document.querySelector('.workflow-step-modal_scroll-area .has-error');
        firstError.scrollIntoView();
      }, 0);

      return;
    }

    const value = this.form.value;
    if (!value.reassignTo?.contactId) delete value.reassignTo;

    this.activeModal.close(value);
  }

  onContactChange(contact: CompanyContact): void {
    this.form.get('reassignTo.contactId').patchValue(contact.id);
    this.selectedContact.set(contact);
  }

  restoreContactData(): void {
    const contactId = this.form.get('reassignTo')?.get('contactId')?.value;
    if (!contactId) return;

    this.contactLoading.set(true);

    this.networkService
      .searchCompanyContacts({
        AssociatedUserIdsToInclude: [contactId],
      })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(({ data }) => {
          if (data?.length === 0) throw new Error('No contact found');

          const [{ contact }] = data;
          if (contact.id !== contactId) throw new Error('Contact ID mismatch');

          this.selectedContact.set(contact);
        }),
        catchError(() => {
          this.selectedContact.set(null);
          this.form.get('reassignTo.contactId').patchValue(null);

          return of(null);
        }),
        finalize(() => {
          this.contactLoading.set(false);
        }),
      )
      .subscribe();
  }
}
