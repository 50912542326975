import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { injectDialogData } from '@design/misc/dialog.helpers';

import { SelfAssignableWorkflowLinkCopyBoxComponent } from './self-assignable-workflow-link-copy-box/self-assignable-workflow-link-copy-box.component';

export interface SelfAssignableWorkflowLinkPreviewDialogData {
  linkId: string;
}

export type SelfAssignableWorkflowLinkPreviewDialogResult = unknown;

@Component({
  selector: 'cc-self-assignable-workflow-link-preview-dialog',
  standalone: true,
  imports: [ButtonComponent, TranslateModule, SelfAssignableWorkflowLinkCopyBoxComponent],
  templateUrl: './self-assignable-workflow-link-preview-dialog.component.html',
  styleUrl: './self-assignable-workflow-link-preview-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfAssignableWorkflowLinkPreviewDialogComponent {
  protected readonly dialogData = injectDialogData<SelfAssignableWorkflowLinkPreviewDialogData>();
  protected readonly dialogRef: DialogRef<SelfAssignableWorkflowLinkPreviewDialogResult> = inject(DialogRef);

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
}
