<div class="modal-header">
  <button
    (click)="activeModal.dismiss()"
    [appFocusable]
    aria-label="Close"
    class="close-btn"
    type="button"
  >
    <img
      alt="{{ 'common.buttons.close' | translate }}"
      appAssetSrc="assets/svg/common/cross.svg"
    />
  </button>
</div>

<div class="modal-body">
  <form
    #ngForm="ngForm"
    (ngSubmit)="save()"
    [formGroup]="form"
    class="workflow-step-modal_form"
  >
    <div class="workflow-step-modal_header">
      <div>
        <span>{{ 'common.labels.step' | translate }}: </span>
        <span>{{ 'setFieldValuesModal.title' | translate }}</span>
      </div>
      <div>
        <div>{{ 'setFieldValuesModal.titleMsg' | translate }}</div>
        <button
          class="btn btn-primary submit-btn"
          type="submit"
        >
          {{ 'common.buttons.save&close' | translate }}
        </button>
      </div>
    </div>

    <div
      [ngStyle]="{ height: maxScrollAreaHeight }"
      class="workflow-step-modal_scroll-area"
    >
      @if (!fields?.length) {
        <loader></loader>
      }
      @if (fields?.length) {
        <scrollable-area #scrollArea>
          <div
            [ngClass]="{
              'has-error': ngForm.submitted && form.get('name').invalid,
            }"
            class="form-group"
          >
            <label
              class="required"
              for="stepName"
              >{{ 'workflows.controls.stepName.label' | translate }}</label
            >
            <input
              formControlName="name"
              id="stepName"
              placeholder="{{ 'workflows.controls.stepName.placeholder' | translate }}"
            />
            <form-error-messages
              [control]="form.get('name')"
              [showErrors]="ngForm.submitted"
            >
            </form-error-messages>
          </div>
          <div
            [ngClass]="{
              'has-error': ngForm.submitted && form.get('description').invalid,
            }"
            class="form-group"
          >
            <label
              class="required"
              for="description"
              >{{ 'workflows.controls.stepDescription.label' | translate }}</label
            >
            <textarea
              formControlName="description"
              id="description"
              placeholder="{{ 'workflows.controls.stepDescription.placeholder' | translate }}"
            >
            </textarea>
            <form-error-messages
              [control]="form.get('description')"
              [showErrors]="ngForm.submitted"
            >
            </form-error-messages>
          </div>

          <div class="field-values-section">
            <h3 class="field-values-title">{{ 'setFieldValuesModal.fieldValues' | translate }}</h3>
            @for (fieldValue of fieldValues; track $index) {
              <div class="field-value-item">
                <div class="field-value-body">
                  <cc-field-value-setter
                    (valueChange)="onFieldValueChange($event, $index)"
                    [fields]="fields"
                    [availableFields]="getAvailableFields(fieldValue.fieldId)"
                    [showErrors]="ngForm.submitted"
                    [value]="fieldValue"
                  />
                </div>
                <div class="field-value-buttons">
                  @if (hasAvailableFields) {
                    <div
                      (click)="addFieldValue()"
                      [appFocusable]
                      class="add-field-value-btn"
                    >
                      <img appAssetSrc="assets/svg/common/cross-white.svg" />
                    </div>
                  }
                  @if (fieldValues.length > 1) {
                    <div
                      (click)="removeFieldValue($index)"
                      [appFocusable]
                      class="remove-field-value-btn"
                    >
                      <img appAssetSrc="assets/svg/common/cross-blue.svg" />
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </scrollable-area>
      }
    </div>
  </form>
</div>
