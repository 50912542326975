import { Dialog } from '@angular/cdk/dialog';
import { Component, Input, OnInit, ViewEncapsulation, inject } from '@angular/core';
import {
  NgbActiveModal,
  NgbDropdown,
  NgbDropdownMenu,
  NgbDropdownToggle,
  type NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { CreateFolderComponent } from '@clover/files/modals/create-folder/create-folder.component';
import { DeleteFolderComponent } from '@clover/files/modals/delete-folder/delete-folder.component';
import { RenameFolderComponent } from '@clover/files/modals/rename-folder/rename-folder.component';
import { type Folder } from '@clover/files/models/folder';
import {
  SelfAssignableWorkflowSettingsDialogComponent,
  type SelfAssignableWorkflowSettingsDialogData,
  type SelfAssignableWorkflowSettingsDialogResult,
} from '@clover/workflows-v2/modals/self-assignable-workflow-settings-dialog/self-assignable-workflow-settings-dialog.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { type TableColumnConfig, type TableConfig, TableLayout } from '@core/models/table';
import { ModalService } from '@core/services/modal.service';

import { ScrollableAreaComponent } from '../../../core/components/scrollable-area/scrollable-area.component';
import { TableComponent } from '../../../core/components/table/table.component';
import { TooltipComponent } from '../../../core/components/tooltip/tooltip.component';
import { type Workflow, WorkflowType } from '../../models/workflow';
import { WorkflowsService } from '../../workflows.service';
import { ShareWorkflowModalComponent } from '../share-workflow-modal/share-workflow-modal.component';

@Component({
  selector: 'app-workflow-library-modal',
  templateUrl: './workflow-library-modal.component.html',
  styleUrls: ['./workflow-library-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ScrollableAreaComponent,
    FocusableDirective,
    AssetSrcDirective,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    TableComponent,
    TooltipComponent,
    TranslateModule,
  ],
})
export class WorkflowLibraryModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  public readonly workflowsService = inject(WorkflowsService);
  private readonly modalService = inject(ModalService);
  private readonly dialog = inject(Dialog);

  @Input() publishMode = false;
  @Input() workflowId: number;
  @Input() workflowType: WorkflowType;
  @Input() productOnly = false;
  @Input() companyOnly = false;

  // @Input()

  public folders: Folder[] = [];
  public selectedFolder: Folder;
  public data: Workflow[] = [];
  public tableConfig: TableConfig;
  public layout: TableLayout = TableLayout.Grid;
  public isLoading = false;

  public get TableLayout(): typeof TableLayout {
    return TableLayout;
  }

  public get loadUrl(): string {
    if (!this.selectedFolder) {
      return null;
    }
    return `/api/companies/my/workflows/search?status=Published&folderId=${this.selectedFolder.id}&mode=${
      this.publishMode ? 'All' : 'Assigner'
    }`;
  }

  public ngOnInit(): void {
    this.loadFolders().then(() => {
      if (this.folders.length > 0) {
        this.selectedFolder = this.folders[0];
        this.tableConfig = this.generateTableConfig();
      }
    });
  }

  public changeLayout(): void {
    this.layout = this.layout === TableLayout.Table ? TableLayout.Grid : TableLayout.Table;
    this.tableConfig = this.generateTableConfig();
  }

  public selectFolder(folder: Folder): void {
    if (!folder) {
      this.selectedFolder = null;
      return;
    }

    if (this.selectedFolder && this.selectedFolder.id === folder.id) {
      return;
    }

    this.selectedFolder = folder;
    this.tableConfig = this.generateTableConfig();
  }

  public async loadFolders(): Promise<any> {
    await this.workflowsService.getWorkflowFolders().then((res) => {
      this.folders = res;
    });
  }

  public createFolder(): void {
    this.modalService
      .open({
        content: CreateFolderComponent,
        inputs: {
          defaultName: this.getNewFolderName(this.folders),
          method: async (data) => await this.workflowsService.createFolder(data),
        },
        options: {
          size: 'sm',
        },
      })
      .result.then((folder) => {
        this.selectFolder(folder);
        this.loadFolders();
      })
      .catch(() => {});
  }

  public deleteFolder(folder: Folder): void {
    this.modalService
      .open({
        content: DeleteFolderComponent,
        inputs: {
          folder,
          method: async (id) => await this.workflowsService.deleteFolder(id),
        },
        options: {
          size: 'sm',
        },
      })
      .result.then(() => {
        if (this.selectedFolder && this.selectedFolder.id === folder.id) {
          this.selectFolder(this.folders.find((f) => f.id !== folder.id));
        }
        this.loadFolders();
      })
      .catch(() => {});
  }

  public renameFolder(folder: Folder): void {
    this.modalService
      .open({
        content: RenameFolderComponent,
        inputs: {
          folder,
          method: async (id, data) => await this.workflowsService.renameFolder(id, data),
        },
        options: {
          size: 'sm',
        },
      })
      .result.then((name: string) => {
        if (this.selectedFolder && this.selectedFolder.id === folder.id) {
          this.selectedFolder.name = name;
        }
        this.loadFolders();
      })
      .catch(() => {});
  }

  public onWorkflowClick(workflow: Workflow): void {
    if (!this.publishMode) {
      this.activeModal.close(workflow);
    }
  }

  public publish(): void {
    if (!this.selectedFolder || this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.workflowsService
      .publishWorkflow(this.workflowId, this.selectedFolder.id)
      .then(() => {
        this.shareWorkflow().result.finally(() => {
          this.activeModal.close();
        });
      })
      .then(() => {
        if (!this.publishMode || this.workflowType !== WorkflowType.Company) return;

        this.dialog.open<SelfAssignableWorkflowSettingsDialogResult, SelfAssignableWorkflowSettingsDialogData>(
          SelfAssignableWorkflowSettingsDialogComponent,
          {
            data: {
              workflowId: this.workflowId,
            },
          },
        );
      })
      .catch((res) => {
        this.activeModal.dismiss(res.error);
      })
      .finally(() => (this.isLoading = false));
  }

  public shareWorkflow(): NgbModalRef {
    return this.modalService.open({
      content: ShareWorkflowModalComponent,
      inputs: {
        workflowId: this.workflowId,
        isPublishing: true,
      },
      options: {
        size: 'sm',
      },
    });
  }

  public isPublishing(): boolean {
    return this.isLoading;
  }

  public isWorkflowDisabled(workflow: Workflow): boolean {
    return (
      (this.productOnly && workflow.type !== WorkflowType.Product) ||
      (this.companyOnly && workflow.type !== WorkflowType.Company)
    );
  }

  private getNewFolderName(folders: Folder[]): string {
    const base = 'New Folder';
    let name = base;
    let index = 1;
    while (folders.findIndex((f) => f.name === name) !== -1) {
      name = `${base} ${index}`;
      index++;
    }
    return name;
  }

  private generateTableConfig(): TableConfig {
    const columns: TableColumnConfig[] = [
      {
        name: 'name',
        label: 'common.labels.name',
        sortable: true,
        minWidth: '200px',
        maxWidth: '10fr',
      },
      {
        name: 'connectionType',
        icon: 'assets/svg/files/grid.svg',
        onClick: this.changeLayout.bind(this),
        sortable: false,
        minWidth: '50px',
        maxWidth: '50px',
      },
    ];

    if (this.layout === TableLayout.Table) {
      columns.splice(1, 0, {
        name: 'type',
        label: 'common.labels.type',
        sortable: true,
        minWidth: '150px',
        maxWidth: '2fr',
      });
    }

    const buttons = [];
    if (this.publishMode) {
      buttons.push({
        label: 'workflowLibrary.buttons.publishWorkflow',
        isLoadingFunc: this.isPublishing.bind(this),
        onClick: this.publish.bind(this),
        class: 'btn btn-primary submit-btn',
      });
    }

    return {
      loadUrl: this.loadUrl,
      loadLimit: this.layout === TableLayout.Table ? 12 : 20,
      filters: [],
      layout: this.layout,
      buttons,
      columns,
    };
  }
}
