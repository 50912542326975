<div class="self-assignable-workflow-settings-dialog">
  <div class="self-assignable-workflow-settings-dialog__header">
    <div class="self-assignable-workflow-settings-dialog__header-title">Self-assignment settings</div>

    <app-button
      [ccTooltip]="'common.buttons.close' | translate"
      [type]="ButtonType.Ghost"
      [size]="ButtonSize.Big"
      (click)="dialogRef.close()"
    >
      <i
        class="icon-close"
        slot="ghost-icon"
      ></i>
    </app-button>
  </div>

  <div
    class="self-assignable-workflow-settings-dialog__content--wrapper"
    [ccAutoAnimate]
  >
    @if (loadingStatus() === 'loaded') {
      <ng-scrollbar
        class="self-assignable-workflow-settings-dialog__content--scrollbar"
        (scrolled)="loadNextContacts$.next()"
        [infiniteScrollDistance]="4"
        [infiniteScrollThrottle]="0"
        [infiniteScrollUpDistance]="4"
        [scrollWindow]="false"
        orientation="vertical"
        cdkScrollable
        infiniteScroll
      >
        <div class="self-assignable-workflow-settings-dialog__content">
          <ng-container [formGroup]="assignmentLinkForm">
            <div
              class="self-assignable-workflow-settings-dialog__content-main"
              [ccAutoAnimate]
            >
              <app-toggle
                formControlName="enabled"
                label="Make this workflow self-assignable"
                description="Enable self-assignment via a unique link. Anyone with the link can assign this workflow to themselves."
              />

              @if (linkEnabledControl.value && assignmentLinkConfiguration().enabled) {
                <cc-self-assignable-workflow-link-copy-box
                  class="self-assignable-workflow-settings-dialog__content-main-link"
                  [linkId]="assignmentLinkConfiguration().id"
                />
              }

              <div class="self-assignable-workflow-settings-dialog__content-main-divider"></div>
            </div>

            <cc-table ccPadding="small">
              <ng-container slot="cc-table-title">Default assigner </ng-container>

              <ng-container slot="cc-table">
                <thead>
                  <tr ccTr>
                    <th
                      ccTh
                      ccWidth="50%"
                    >
                      Name
                    </th>
                    <th
                      ccTh
                      ccWidth="50%"
                    >
                      Email
                    </th>
                  </tr>
                </thead>

                <tbody>
                  @for (contact of contacts().data; track contact.id) {
                    <tr
                      ccTr
                      [ccDisabled]="!linkEnabledControl.value || !contact.active"
                      [ccClickable]="true"
                      (click)="assignerIdControl.setValue(contact.id)"
                    >
                      <td ccTd>
                        <div class="name-cell">
                          <app-radio
                            class="name-cell__radio"
                            [checked]="assignerIdControl.value === contact.id"
                          />

                          <cc-user-avatar
                            [id]="contact.id"
                            [src]="contact.avatarUrl"
                            [name]="[contact.firstName, contact.lastName]"
                            [size]="24"
                          />

                          <div>{{ contact.firstName }} {{ contact.lastName }}</div>
                        </div>
                      </td>

                      <td ccTd>{{ contact.email }}</td>
                    </tr>
                  }
                </tbody>
              </ng-container>
            </cc-table>
          </ng-container>
        </div>
      </ng-scrollbar>
    } @else {
      <div class="self-assignable-workflow-settings-dialog__content--loader">
        <cc-loader [size]="144" />
      </div>
    }
  </div>

  <div class="self-assignable-workflow-settings-dialog__actions">
    <app-button
      (click)="dialogRef.close()"
      [type]="ButtonType.Secondary"
      [size]="ButtonSize.Small"
    >
      {{ 'common.buttons.cancel' | translate }}
    </app-button>

    <app-button
      (click)="save()"
      [disabled]="assignmentLinkForm.invalid || saving()"
      [type]="ButtonType.Primary"
      [size]="ButtonSize.Small"
    >
      {{ 'common.buttons.save' | translate }}
    </app-button>
  </div>
</div>
