<div class="self-assignable-workflow-link-copy-box">
  <div
    #linkContainer
    class="self-assignable-workflow-link-copy-box__link"
    (click)="selectText()"
  >
    {{ workflowLink() }}
  </div>

  <app-button
    (click)="copy()"
    [ccTooltip]="'Copy link'"
    [type]="ButtonType.Ghost"
    [size]="ButtonSize.Big"
  >
    <i
      class="icon-copy"
      slot="ghost-icon"
    ></i>
  </app-button>
</div>
